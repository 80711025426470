import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CONTENT} from '../../content-management/content';
import {Router} from '@angular/router';
import {ROUTINGCONSTANTS} from '../../shared/constants/routing-constants';
import {HomepageService} from './homepage.service';
import {Claim} from '../../models/claim';
import {DataService} from '../../core/services/data.service';
import {IMAGECONSTANTS} from '../../shared/constants/image-constants';
import {CommonService} from '../../shared/services/common.service';
import {DatePipe} from '@angular/common';
import {GtmEventsService} from '../../shared/services/gtm-events.service';
import {catchError, first, map, mergeMap,tap} from 'rxjs/operators';
import {ForgotPasswordService} from '../../shared/components/forgot-password/forgot-password.service';
import {GetSecurityQuestionRequest} from '../../models/request/get-security-question-request';
import {BillingPaymentService} from '../../shared/services/billing-payment.service';
import {ClaimsService} from '../claims/all-claims/claims.service';
import {PolicyDetails} from '../../models/policy-details';
import {PolicyDetailsSession} from '../../models/policy-details-session';
import {MppQuestionsService} from '../../shared/services/mpp-questions.service';
import {SliderModel} from '../../models/slider-model';
import * as _ from 'lodash';
import {AccountResponse} from '../../models/response/account-response';
import {AccountService} from '../../shared/services/account.service';
import {ClaimDetailsResponse} from '../../models/response/claim-details-response';
import {EncrDecrService} from '../../core/services/encr-decr.service';
import {HYPERLINKSCONSTANTS} from '../../shared/constants/hyperlinks-constants';
import {JwtService} from '../../core/services/jwt.service';
import {AuthService} from '../../core/services/auth.service';
import {environment} from '../../../environments/environment';
import {NewPetDetails} from '../../models/new-pet-details';
import { ContentfulService } from '../../shared/services/contentful.service';
import CONTENTFULIDS from '../../shared/constants/contentful-ids';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { policySessionContants } from '../../shared/constants/policy-session-constants';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MyPets } from '../../models/my-pets';
import { BannerCarouselContentful } from '../../models/banner-carousel-contentful.model';
import { PartnershipService } from '../../shared/services/partnership.service';
import { FullstoryService } from '../../shared/services/fullstory.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class HomepageComponent implements OnInit, AfterViewInit 
{
  public content = CONTENT;
  public routingConstants = ROUTINGCONSTANTS;
  public imageConstants = IMAGECONSTANTS;
  public hyperLinkConstants = HYPERLINKSCONSTANTS;
  public userId: string;
  public userName: string;
  public memberName: string;
  public claimData: Claim[];
  public mostRecentClaims: Claim[] = [];
  public displayProgressSpinner = 0;
  public getSecurityQtn: GetSecurityQuestionRequest = {};
  public policyDetailsList: MyPets[] = [];
  public sliderModel: SliderModel;
  public petSpeciesUrl: string;
  public policyNumber: string;
  public currentSlideNumber = 0;
  private addNewPetURL = environment.qecBaseUrl;
  private referralUrl = environment.referralUrl;
  private sweepStakesQecUrl = environment.sweepStakesQecUrl;
  public newPetDetails: NewPetDetails;
  public rxclaimOptin = true;
  public sweepstakesMobileUrl = '';
  public sweepstakesDesktopUrl = '';
  public bannerURL = '';
  public bannerURLTitle = '';
  public contentfulIds = CONTENTFULIDS;
  public sweepstakesMobileTitle = '';
  public sweepstakesDesktopTitle = '';
  public bannerTextHtml = '';
  public tokenPolicyList: any = [];
  public policyApiCount = 0;
  public policyClaims: any;
  public isMPIPolicy = false;
  public isClaimLoaded = false;
  public bannerImagesArr:BannerCarouselContentful[]=[];
  public bannerMobileImagesArr:BannerCarouselContentful[]=[];
  public dynamicContent: any;
  public policyInfoLogoUrl = '';
  public nwHeaderLogoUrl = '';
  public cobrandingHeaderLogoUrl = '';
  public cobrandingDetection = false;
  public partnershipDetails: any;
  public policyDetails: any;
  public iconObj: any;
  
  constructor(private billingPaymentService: BillingPaymentService,
              private router: Router,
              private homepageService: HomepageService,
              private dataService: DataService,
              private commonService: CommonService,
              private datePipe: DatePipe,
              private forgotPasswordService: ForgotPasswordService,
              private gtmService: GtmEventsService,
              private claimService: ClaimsService,
              private accountService: AccountService,
              private cdRef: ChangeDetectorRef,
              private encDcrService: EncrDecrService,
              private authService: AuthService,
              private mppQuestionsService: MppQuestionsService,
              private jwtService: JwtService,
              private contentfulService: ContentfulService,
              private partnershipService: PartnershipService,
              private fullstoryService: FullstoryService,
              private httpClient: HttpClient) {
    this.getUserId();
    this.getDesktopBannerUrl();
    this.getMobileBannerUrl();
    this.getBannerLink();
  }

  ngOnInit() 
  {
    this.getNewPolicyDetailsList(0);
    this.getFirstName();
    this.getOptInInfo();
    this.getNWHeaderLogo();
    this.getSpeciesIcons();
    this.dynamicContent = JSON.parse(sessionStorage.getItem('portalContenful'));
  }

  checkForPetType(pet) 
  {
    switch(pet?.species?.toUpperCase())
    {
      case "CANINE":
        this.petSpeciesUrl = this.iconObj?.canineIcon ? this.iconObj?.canineIcon : this.imageConstants.svgLogos.dogIcon;
        break; 
      case "FELINE":
        this.petSpeciesUrl = this.iconObj?.felineIcon ? this.iconObj?.felineIcon : this.imageConstants.svgLogos.catIcon;
        break;
      case "REPTILE":
        this.petSpeciesUrl = this.iconObj?.reptileIcon ? this.iconObj?.reptileIcon : this.imageConstants.svgLogos.reptileIcon;
        break;  
      case "AVIAN":
        this.petSpeciesUrl = this.iconObj?.avianIcon ? this.iconObj?.avianIcon : this.imageConstants.svgLogos.avianIcon;
        break; 
      case "EXOTIC":
        this.petSpeciesUrl = this.iconObj?.exoticIcon ? this.iconObj?.exoticIcon : this.imageConstants.svgLogos.exoticIcon;
        break; 
      default:
        this.petSpeciesUrl = this.iconObj?.canineIcon ? this.iconObj?.canineIcon : this.imageConstants.svgLogos.dogIcon;
        break;
    }
  }

  getSpeciesIcons() {
    const portalSpeciesIcons = JSON.parse(sessionStorage.getItem('portalSpeciesIcons'));
    if(portalSpeciesIcons) {
      this.filterIcons(portalSpeciesIcons);
    }
  }

  filterIcons(iconList) {
    if(iconList) {
      const canineIcon = iconList?.canineIcon?.fields?.file?.url;
      const felineIcon = iconList?.felineIcon?.fields?.file?.url;
      const exoticIcon = iconList?.exoticIcon?.fields?.file?.url;
      const reptileIcon = iconList?.reptileIcon?.fields?.file?.url;
      const avianIcon = iconList?.avianIcon?.fields?.file?.url;    
      this.iconObj = {
        canineIcon : canineIcon,
        felineIcon: felineIcon,
        reptileIcon: reptileIcon,
        avianIcon: avianIcon,
        exoticIcon: exoticIcon       
      }
    }
  }

  getFirstName() 
  {
    const name = sessionStorage.getItem('userName');
    if (name) 
    {
      this.userName = name;
      this.memberName = sessionStorage.getItem('memberName');
    } 
    else 
    {
      this.getFirstNameFromToken();
    }
  }

  async getNWHeaderLogo() 
  {
    const res = await this.contentfulService.getAsset(this.contentfulIds.assets.nationWideHeaderLogo);
    if (res) 
    {
      this.nwHeaderLogoUrl = res.file.url;
    }
  }

  async getMobileBannerUrl() 
  {
    const res = await this.contentfulService.getBannerEntry(this.contentfulIds.entries.bannerCarouselMobile);
    if (res) 
    {
      this.filterMobBannerImages(res);
    }
  }

  filterMobBannerImages(bannerMobImagesBlocks)
  {
    for (let entry of bannerMobImagesBlocks.listImages) {
       let bannerMobObj: BannerCarouselContentful = new BannerCarouselContentful();
       bannerMobObj.ImageUrl=entry.fields.banner[0].fields.image.fields.file.url;
       bannerMobObj.ImageText=entry.fields.content;
       bannerMobObj.ImageLink=entry.fields.link
      this.bannerMobileImagesArr.push(bannerMobObj);
    }
  }

  async getDesktopBannerUrl() 
  {
      const res = await this.contentfulService.getBannerEntry(this.contentfulIds.entries.bannerCarouselDesktop);
      if (res) 
      {
        this.filterDeskBannerImages(res);
      }
  }

  filterDeskBannerImages(bannerImagesBlocks)
  {
    for (let entry of bannerImagesBlocks.listImages) {
       let bannerObj: BannerCarouselContentful = new BannerCarouselContentful();
       bannerObj.ImageUrl=entry.fields.banner[0].fields.image.fields.file.url;
       bannerObj.ImageText=entry.fields.content;
       bannerObj.ImageLink=entry.fields.link
      this.bannerImagesArr.push(bannerObj);
    }
  }

  async getBannerLink() 
  {
    const res = await this.contentfulService.getTextBlock(this.contentfulIds.entries.bannerLink);
    if (res) 
    {
      this.bannerURL = res.fields.content;
      this.bannerURLTitle = res.fields.contentName;
    }
  }
  getFirstNameFromToken() 
  {
    var currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt = this.jwtService.DecodeToken(currentUser);
    this.userName = decodeJwt['firstName'];
    this.memberName = decodeJwt['firstName'] + ' ' + decodeJwt['lastName'];
    sessionStorage.setItem('userName', this.userName);
    sessionStorage.setItem('memberName', this.memberName);
  }

  ngAfterViewInit() 
  {
    this.cdRef.detectChanges();
  }

  setCarouselGTMEvent(policyNumber) 
  {
    const event = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'Carousel Change',
      eventAction: 'Homepage Carousel Change',
      eventLabel: 'Carousel Change on the HomePage',
      eventValue: `Carousel Changed to: ${policyNumber}`,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(event.event, { message: event });
  }

  onActiveSlideChanged(slide: number) 
  {
    this.currentSlideNumber = slide;
    this.sliderModel = new SliderModel();
    if(this.tokenPolicyList.length>1 && this.tokenPolicyList.length!=this.policyApiCount)
    {
      this.getNewPolicyDetailsList(this.policyApiCount);
    }
    this.addSpinner();
    const policy = this.policyDetailsList[slide];
    this.policyNumber = policy.policyNumber;
    this.isMPIPolicy = policy.planCode === "ModularPetInsurance";  
    sessionStorage.setItem("activePolicy", policy.policyNumber);
    sessionStorage.setItem("activeMemberId", policy.memberId);
    sessionStorage.setItem("activeAccountId", policy.accountId);
    this.isClaimLoaded = false;
    this.homepageService.getPetClaims(policy.policyNumber, Number(policy.renewalNumber)).subscribe({next: (recentClaim:any) =>
    {
      this.policyClaims = recentClaim;
      this.checkForPetType(policy.pet);
      this.onSlideChange(policy, recentClaim);
      this.setCarouselGTMEvent(policy.policyNumber);
    },
    error: (err) => 
    {
      this.isClaimLoaded = true;
    },
    complete: () => 
    {
      this.isClaimLoaded = true;
      this.subtractSpinner();
    }});
  }

  onSlideChange(policy, recentClaim) 
  {
    this.sliderModel.policy = policy;
    if(recentClaim && recentClaim.ClaimSummaryResponse && recentClaim.ClaimSummaryResponse.ClaimSummary)
    {
      let claims =recentClaim.ClaimSummaryResponse.ClaimSummary.sort((curr,acc) =>acc.reportedDate.localeCompare(curr.reportedDate));
      recentClaim.claims = claims;
    }

    if (recentClaim && recentClaim.claims && recentClaim.claims.length > 0) 
    {
      this.sliderModel.claim = recentClaim.claims[0];
    } 
    else 
    {
      this.pushEmptyMostRecentClaim(policy.policyNumber);
    }
    this.sliderModel.claim = this.commonService.setStatusBar(this.sliderModel.claim);
    this.dataService.mostRecentClaims = this.mostRecentClaims;
  }

  getNewPolicyDetailsList(CurrentPolicy) 
  {
    this.addSpinner();
    const currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt=this.jwtService.DecodeToken(currentUser);
    this.tokenPolicyList = decodeJwt["policyList"]
    this.policyApiCount++;     
      this.billingPaymentService.getPolicyDetail(this.tokenPolicyList[CurrentPolicy].policyNumber).pipe(
        mergeMap((policyDetails: any) =>
        {
          if (policyDetails && policyDetails!==undefined)
          {
            this.policyDetails = policyDetails;
            const partnerId = policyDetails.partnerSPID ? policyDetails.partnerSPID : (policyDetails.groupId ? policyDetails.groupId: null);
            if(partnerId)
            {
               return this.partnershipService.getPartnershipInfo(partnerId).pipe(
                mergeMap((partnershipDetails: any) =>{
                  policyDetails.coBranding = partnershipDetails.coBranding;
                  if(policyDetails.coBranding)
                  {
                    policyDetails.cobrandingStatus = true;
                  }
                  
                  this.policyDetails = policyDetails;
                  if(policyDetails.cobrandingStatus && this.policyInfoLogoUrl === '' && this.cobrandingHeaderLogoUrl === '')
                  {
                    return this.contentfulService.getCobrandingEntry(policyDetails.coBranding).pipe(
                      tap((contentfulData: any) => {
                        this.policyInfoLogoUrl = contentfulData.policyInfoLogoUrl;
                        this.cobrandingHeaderLogoUrl = contentfulData.cobrandingHeaderLogoUrl;
                      })
                    );
                  }
                  return of('Partner is not cobranding');
                }),
                catchError(() => of('Partner is not cobranding'))
              )
            }
            return of('No associated group id or spid to policy');
          }
          else
          {
            if(this.tokenPolicyList.length>CurrentPolicy+1)
            {
              this.subtractSpinner();
              this.getNewPolicyDetailsList(CurrentPolicy+1);
            }
            else{
              this.subtractSpinner();
            }
          }
        })
      ).subscribe({
          next: () =>{
            let resultLenght = this.policyDetailsList.length;
            this.policyDetailsList.splice(resultLenght,0,this.policyDetails);
          },
          complete:() =>{
            this.subtractSpinner();
          }
      });
  }

  getPolicyDetailsList() 
  {
    this.addSpinner();
    const currentUser = sessionStorage.getItem('currentUser');
    const decodeJwt=this.jwtService.DecodeToken(currentUser);
    this.tokenPolicyList = decodeJwt['policyList'];
    this.billingPaymentService.getPolicyDetailsNewList(this.tokenPolicyList).subscribe({
      next: data => 
      {
        if (data) 
        {
          const policyDataList = data.filter(x => x !== undefined);
          if (policyDataList && policyDataList.length>0) 
          {
            const responseSessionList: any = [];
            policyDataList.forEach(policyDetail => 
            {
              var poicyDetailSession: PolicyDetailsSession = new PolicyDetailsSession();
              Object.assign(poicyDetailSession, _.pick(policyDetail, policySessionContants.poicyDetailSessionKeys));
              responseSessionList.push(poicyDetailSession);
            });
            sessionStorage.setItem('policyList', JSON.stringify(responseSessionList));
          }
        }
      },
      complete: () => 
      {
        this.subtractSpinner();
      }
    });
  }

  goToPets() 
  {
    const gtmEvent = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'Image Click',
      eventAction: 'Clicking Pet Image Navigates to MyPets Page',
      eventLabel: 'Pet Image',
      eventValue: 'Navigated To MyPets Page',
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(gtmEvent.event, { message: gtmEvent });
    this.router.navigate([this.routingConstants.pets, this.encDcrService.set(this.sliderModel.policy.policyNumber)]);
  }

  populateSecurityQtn() 
  {
    this.getSecurityQtn.userId = this.userId;
    return this.getSecurityQtn;
  }

  getEmptyPetsList() 
  {
    this.addSpinner();
    this.mppQuestionsService.getEmptyPetsList().subscribe({
      next: data => 
      {
        this.subtractSpinner();
        if (data && data.pets && data.pets.length) {
          if (this.loadGoogleMap()) {
            setTimeout(() => {
              this.router.navigate([this.routingConstants.mppQuestions]);
            }, 0);
          }
        }
      },
      error: () => this.subtractSpinner()
    });
  }

  async loadGoogleMap(): Promise<Observable<boolean>> {
    return this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyAjy4sEdBPKzCKbNpVx6Yyzjp0osS8_tc0&libraries=places', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }

  checkForSecurityQuestion() {
    this.addSpinner();
    this.forgotPasswordService
    .securityQuestion(this.populateSecurityQtn())
    .pipe(first())
    .subscribe({
      next: response => 
      {
        if (!response.securityQuestion) 
        {
          this.router.navigate([this.routingConstants.editSecQs, {route: 'homePage'}]);
        }
      },
      complete: () => this.subtractSpinner()
    });
  }

  getUserId() 
  {
    this.userId = sessionStorage.getItem('userId');
  }

  goToSweepStakes() 
  {
    if (window.innerWidth <= 768) 
    {
      window.open(this.hyperLinkConstants.sweepStakes, '_parent');
    } 
    else 
    {
      window.open(this.hyperLinkConstants.sweepStakes, '_blank');
    }
  }

  getEnrollNewPetDetails() 
  {
    const memberId = sessionStorage.getItem("activeMemberId");
    if (memberId == null) 
    {
      setTimeout(() => 
      {
        this.getEnrollNewPetDetails();
      }, 250);
    } 
    else 
    {
      this.homepageService.getNewPetDetails(memberId).subscribe((data) => 
      {
        this.newPetDetails = data;
        const zip = String(data.zipCode);
        const code = zip && zip.split('-')[0];
        this.newPetDetails.zipCode = Number(code);
      }, () => 
      {
        this.updateContactErrorModal();
      }
      );
    }
  }

  updateContactErrorModal() 
  {
    this.commonService.setDialogModal({
      title: 'Error',
      header: 'Error updating information.',
      content: ''
    });
  }

  setInternalNavGTM(page) 
  {
    const gtmEvents = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Homepage Helpful Links',
      eventLabel: 'Homepage Links',
      eventValue: page,
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(gtmEvents.event, { message: gtmEvents });
  }

  navigateToReferral() 
  {
    this.setInternalNavGTM('Link to Navigate to Referral Page from sweepStakes');
    const referralUrl = this.bannerURL;
    if (window.innerWidth <= 768) 
    {
      window.open(referralUrl, '_parent');
    } 
    else 
    {
      window.open(referralUrl, '_blank');
    }
  }

  navigateToQECSweepStakes() 
  {
    this.setInternalNavGTM('Link to Navigate to Referral Page from sweepStakes');
    const qecUrl = this.sweepStakesQecUrl;
    if (window.innerWidth <= 768) 
    {
      window.open(qecUrl, '_parent');
    } 
    else 
    {
      window.open(qecUrl, '_blank');
    }
  }

  navigateToQEC() 
  {
    this.setInternalNavGTM('Link to Enroll a New Pet from sweepStakes');
    this.getEnrollNewPetDetails();
    const qecUrl = this.addNewPetURL +
      '?FirstName=' +
      this.newPetDetails.firstName +
      '&LastName=' +
      this.newPetDetails.lastName +
      '&Street=' +
      this.newPetDetails.streetAddress1 +
      this.newPetDetails.streetAddress2 +
      '&City=' +
      this.newPetDetails.city +
      '&State=' +
      this.newPetDetails.state +
      '&Zipcode=' +
      this.newPetDetails.zipCode +
      '&Phone=' +
      (this.newPetDetails.homePhone || this.newPetDetails.mobilePhone || this.newPetDetails.workPhone) +
      '&Email=' +
      this.newPetDetails.email +
      '&OM=' +
      this.newPetDetails.om +
      '&IC=' +
      this.newPetDetails.ic +
      '&ACTION=' +
      this.newPetDetails.action;
    if (window.innerWidth <= 768) 
    {
      window.open(qecUrl, '_parent');
    } 
    else 
    {
      window.open(qecUrl, '_blank');
    }
  }

  navigateToMissingPolicy(policyNumber) 
  {
    this.setGTMEvent();
    this.router.navigate([this.routingConstants.missingPolicy]);
  }

  setGTMEvent() 
  {
    const gtmEvent = 
    {
      event: 'Portal Data Layer',
      eventCategory: 'AnchorLink Click',
      eventAction: 'Missing Policy Link',
      eventLabel: 'Navigate To Missing Policy Page',
      eventValue: 'Missing Policy Link',
      userId: sessionStorage.getItem('userId')
    };
    this.fullstoryService.recordCustomEvent(gtmEvent.event, { message: gtmEvent });
  }

  pushEmptyMostRecentClaim(policyNumber: string) 
  {
    this.sliderModel.claim = 
    {
      policyNumber
    };
  }

  addSpinner() 
  {
    this.displayProgressSpinner++;
    this.cdRef.markForCheck();
  }

  subtractSpinner() 
  {
    this.displayProgressSpinner--;
    this.cdRef.markForCheck();
  }

  isDisplayEffective(policyData)
  {
    if(this.tokenPolicyList && policyData)
    {
      const isRENPolicyData = this.tokenPolicyList.some(p=>(p.policyNumber === this.policyNumber && p.sourceSystem==="REN"));
      if(isRENPolicyData)
      {
        return policyData.renewalNumber==="0";
      }
      else
      {
        return policyData.renewalNumber==="1";
      }
    }
    return false;
  }

  getOptInInfo() 
  {
  }

}